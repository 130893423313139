<template>
  <div id="section-content" class="container container-fluid">
    <div class="bar-back">
      <router-link to="/member/lottery/lottoset"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 179px) - 50px)">
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-1 px-2 xtarget col-lotto d-flex flex-row justify-content-between mb-1 pb-0">
        <div class="lotto-title">
            <h4><i class="fas fa-ticket-alt"></i> {{ lotteryInformation ? lotteryInformation.group_name : '' }}</h4>
        </div><!-- end title --->
        <a v-b-modal.rule-by-id href="#" class="btn btn-outline-secondary btn-sm mr-1" >กติกา
            &amp; วิธีเล่น</a>
      </div>

      <div v-if="isLoading">
        <div class="row px-0 mb-1 mx-0">
          <div class="col-4 p-1 pb-0">
            <router-link
            :to="`/member/lottery/result/${id}`"
              style="align-items: center"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column font_size_0_9em pt-2 pb-2">
              <i class="fas fa-award"></i>
              ผล{{ lotteryInformation ? lotteryInformation.group_name : '' }}
            </router-link>
          </div>
          <div class="col-4 p-1 pb-0">
            <router-link
              style="align-items: center"
              :to="`/member/lottery/${id}`"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column font_size_0_9em pt-2 pb-2">
              <i class="fas fa-calculator"></i>
              แทง{{ lotteryInformation ? lotteryInformation.group_name : '' }}
            </router-link>
          </div>
           <div class="col-4 p-1 pb-0">
            <router-link
              style="align-items: center"
              :to="`/member/lottery/poy/${id}`"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column active font_size_0_9em pt-2 pb-2">
              <i class="fas fa-calculator"></i>
              รายการที่แทง
            </router-link>
          </div>
        </div>
        <div class="w-100 my-2 border-bottom"></div>
         <div class="bgwhitealpha shadow-sm rounded p-2 mb-1 xtarget col-lotto d-flex flex-column flex-sm-column flex-md-row justify-content-between">
              <h4 class="mb-0 text-center"><i class="fas fa-list"></i> รายการที่แทง</h4>
              <div><span class="badge badge-primary font-weight-light w-100">ผลรางวัลออกเลขตามสลากหวย ({{ lotteryInformation ? lotteryInformation.group_name : '' }})</span></div>
          </div>
        <div class="bg-white shadow-sm rounded py-2 px-1 mb-5">
          <template v-for="(item,index) in setList" >
              <div class="row p-0 m-0 mb-2 border border-dark" :key="index">
                <div class="col-2 col-sm-2 col-md-1 d-flex flex-column justify-content-center align-items-center bg-secondary text-white p-1"><small style="line-height:1;">ลำดับ</small><b>{{ index+1 }}</b></div >
                <div class="col-6 col-sm-6 col-md-9 p-1 bg-light border border-left-0 border-right-0 border-top-0 d-flex justify-content-center align-items-center"><h4 style="letter-spacing:5px;" class="mb-0">{{ item.number }}</h4></div>
                <div class="col-4 col-sm-4 col-md-2 bg-warning d-flex justify-content-center align-items-center px-0" v-if="item.status == 0 || item.status == 1">
                  <small style="line-height:1.2"><i class="fas fa-circle-notch fa-spin"></i> {{ item.statusName }}</small>
                </div>
                <div class="col-4 col-sm-4 col-md-2 bg-lost d-flex justify-content-center align-items-center px-0"  :class=" item.status == 2  ? 'bg-danger' : 'bg-success'" v-else-if="item.status == 2">
                  <small class="text-white" style="line-height:1.2">{{ item.statusName }}</small>
                </div>
                <div class="col-4 col-sm-4 col-md-2 bg-lost d-flex justify-content-center align-items-center px-0" :class=" item.IsWinPrice  ? 'bg-success' : 'bg-primary'" v-else>
                  <small style="line-height:1.2">{{ item.IsWinPrice ? 'ถูกรางวัล' : 'ไม่ถูกรางวัล' }}</small>
                </div>
                <div class="w-100"></div>
                <div class="col-4 col-sm-4 col-md-1 bg-dark text-white pt-1 px-1"><small>No. #{{ item.id}}</small></div>
                <div class="col-8 col-sm-8 col-md-3 bg-dark text-white text-right text-sm-right text-md-center pt-1 px-1"><small class="smallspan"><span>ซื้อเมื่อ</span><span>{{ getDate(item.dateBetting) }}</span></small></div>
                <div class="col-8 col-sm-8 col-md-3 bg-dark text-white text-left text-sm-left text-md-right pt-1 px-1"><small><span></span>
                  <span class="px-0" v-if="item.status==0"><a @click="confirmReturn(item.id, item.number)" class="btn btn-sm btn-outline-danger w-50" style="text-decoration: none;" target="_blank" >คืนโพย</a></span>
                </small></div>
                <div class="col-4 col-sm-4 col-md-3 bg-dark text-white text-right pt-1 px-1"><small>จำนวน {{ item.amount }} ชุด</small></div>
                <div class="col-12 col-sm-12 col-md-2 bg-light border border-secondary border-bottom-0 text-dark text-center text-sm-center text-md-right pt-1 px-1"><b><small>ราคา</small> {{ item.amount * item.price }} ฿</b></div>
                <!-- <div class="col-12 col-sm-12 col-md-2 bg-dark text-white text-right p-1">
                    <a href="#" class="btn btn-sm btn-secondary btn-block py-0 px-1" data-toggle="modal" data-target="#laosPoy" id="1994-1-LA2330154-1630148400"><i class="fas fa-save"></i> บันทึกสลาก</a>
                </div> -->
              </div>
          </template>
        </div>
      </div>
    </div>
    <app-rule-by-id-modal :advice="this.advice" />
  </div>
</template>

<script>
import moment from 'moment'
import ruleByIdModal from '@/components/modals/RuleByIdModalHtml'
import { mapActions, mapGetters } from 'vuex'
import { listAllBetHistoryService, returnBetServiceById } from '@/services'
export default {
  components: {
    'app-rule-by-id-modal': ruleByIdModal
  },
  async created () {
    this.id = parseInt(this.$route.params.id)
    try {
      this.setLoading(true)
      await this.getInquiryRoundInfomationByID(this.id)
      this.lotteryInformation = this.getInquiryRoundInfomation
      this.advice = this.lotteryInformation.advice
      await this.fetchResultLotto(this.$route.params.id)
      const resp = await listAllBetHistoryService()
      const data = resp.data.map(e => {
        return {
          ...e,
          numberbet: e.numberbet,
          WinAmount: parseInt(e.WinAmount),
          IsWinPrice: JSON.parse(e.IsWinPrice),
          dateBetting: moment(e.dateBetting)
        }
      })
      this.list = data.filter(v => (Number(v.group_id) === Number(4) && Number(this.lotteryInformation.lot_type_id) === Number(v.lot_type_id)))
      this.setList = []
      this.list.forEach((value, index) => {
        value.numberbet[11].list.forEach((valueSub, indexSub) => {
          this.setList.push({
            id: value.TicketId,
            number: valueSub,
            amount: value.numberbet[11].amount[indexSub],
            dateBetting: moment(value.dateBetting),
            status: value.status,
            price: value.price,
            statusName: value.statusName,
            title: value.title,
            IsWinPrice: value.IsWinPrice,
            TypeBet: value.TypeBet
          })
        })
      })
      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
    this.isLoading = true
  },
  methods: {
    ...mapActions(['setLoading', 'fetchResultLotto', 'getInquiryRoundInfomationByID', 'getBalanceInfo']),
    getDate (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    confirmReturn (ticket_id, number_) {
      this.$swal({
        title: 'ยืนยันการคืนลอตเตอรี่ ?',
        text: 'คุณต้องการคืนลอตเตอรี่ ' + number_ + ' ใบนี้ใช่ไหม!',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่ใช่'
      }).then((result) => {
        if (result.isConfirmed) {
          this.callSubmitReturnTicket(ticket_id)
        }
      })
    },
    async callSubmitReturnTicket (ticket_id) {
      this.setLoading(true)
      if (this.enable_del_ticket) {
        this.enable_del_ticket = false
        const respById = await returnBetServiceById(ticket_id)
        if (respById.status_code === 'SUCCESS') {
          const resp = await listAllBetHistoryService()
          const data = resp.data.map(e => {
            return {
              ...e,
              numberbet: e.numberbet,
              WinAmount: parseInt(e.WinAmount),
              IsWinPrice: JSON.parse(e.IsWinPrice),
              dateBetting: moment(e.dateBetting)
            }
          })
          this.list = data.filter(v => (Number(v.group_id) === Number(4) && Number(this.lotteryInformation.lot_type_id) === Number(v.lot_type_id)))
          this.setList = []
          this.list.forEach((value, index) => {
            value.numberbet[11].list.forEach((valueSub, indexSub) => {
              this.setList.push({
                id: value.TicketId,
                number: valueSub,
                amount: value.numberbet[11].amount[indexSub],
                dateBetting: moment(value.dateBetting),
                status: value.status,
                price: value.price,
                statusName: value.statusName,
                title: value.title,
                IsWinPrice: value.IsWinPrice,
                TypeBet: value.TypeBet
              })
            })
          })
          await this.getBalanceInfo()
          this.setLoading(false)
          this.enable_del_ticket = true
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'คืนโพยสำเร็จ',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.setLoading(false)
          this.enable_del_ticket = true
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getInquiryRoundInfomation', 'getResultLotto'])
  },
  data () {
    return {
      id: null,
      lotto: {},
      isLoading: false,
      advice: '',
      lotteryInformation: null,
      list: [],
      setList: [],
      countIndex: 1,
      enable_del_ticket: true
    }
  }
}
</script>
<style type="text/css">

.numLot::-webkit-outer-spin-button,
.numLot::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.title_h p {
  font-weight: 600;
  color: #fff !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background: #006500eb;
  /* background: linear-gradient(143deg, rgba(3, 61, 26, 1) 10%, rgba(1, 70, 29, 1) 35%, rgba(24, 161, 80, 1) 100%) !important; */
}

.lists input:disabled {
  background-color: rgb(51, 51, 51) !important;
  border: 1px solid rgb(141, 144, 149) !important;
}

.bet-keyboard .box-show-number .input-number .lists>input {
  float: left;
  display: block;
  width: 40px;
  height: 45px;
  margin: 0 5px;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  /* border: 1px solid #9f8c5f; */
  border: 1px solid #FF0000;
  position: relative;
  border-radius: 5px;
  color: #005623;
}

.rotate_image {
  -webkit-transform: rotate(350deg);
  -moz-transform: rotate(350deg);
  -ms-transform: rotate(350deg);
  -o-transform: rotate(350deg);
  transform: rotate(350deg);
}

.colon-box {
  float: left;
  display: block;
  width: 25px;
  height: 50px;
  margin: 0 1px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  color: #ffffff;

}

.number-box {
  float: left;
  display: block;
  width: 60px;
  height: 50px;
  margin: 0 5px;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  background-color: #042c3bec;
  border: 1px solid #061527c7;
  position: relative;
  border-radius: 5px;
  color: #ffffff;
  box-shadow: 8px 10px 5px 0px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 8px 10px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 8px 10px 5px 0px rgba(0, 0, 0, 0.4);
}

.box1 {
  background: linear-gradient(143deg, rgba(3, 61, 26, 1) 10%, rgba(1, 70, 29, 1) 35%, rgba(24, 161, 80, 1) 100%) !important;
}

/* .box2 {
  background: linear-gradient(180deg, #444940 0%, #07411f 100%);
  border: 1px solid #3A3F4B;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

} */

.br_radius_top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.br_radius {
  border-radius: 10px !important;
}

.text-blue2 {
  color: #5c80d1 !important;
}

.b_shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.bg_section2 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  background: linear-gradient(143deg, rgb(217 215 215 / 66%) 10%, rgb(255 255 255 / 66%) 35%, rgb(182 178 178 / 71%) 100%);
}

.bg_yellow {
  border-radius: 10px;
  background-color: rgb(250, 231, 126, 0.25);
}

.br_orang {
  color: white !important;
  background-color: #ff6800 !important;
}

.br_bt {
  border-bottom: 1px solid #ebebeb;
}

.bg_red_v1 {
  background-color: #ffd4d4 !important;
}

.btn-black {
  background-color: #292929;
  color: white;
}

.pointer {
  cursor: pointer;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #0059d1 !important;
  border-color: #c3c3c3 !important;
}

.btn-outline-warning.active {
  color: #fff;
  background-color: #0059d1 !important;
  border-color: #c3c3c3 !important;
}

.btn-green {
  color: white;
  background: #52af61;
}

.btn-blue {
  color: white;
  background: #0059d1;
}

.btn-gray {
  color: white;
  background: #292929;
}

.nav-link2.active {
  color: #005623;
  border-bottom: 4px solid #005623;
}

.btn-mode {
  border-radius: 15px !important;
  padding: 2px 0px 3px 0px !important;
  margin: 4px 0.4rem 3px 0.4rem;
}

.subNumber {
  color: red;
  font-size: 0.7rem;
}

.br-dedius-0 {
  border-radius: 0px !important;
}

.bg-gray2 {
  background-color: gray;
}

.bg-gray4 {
  background-color: #69696905 !important;
}

.fw-900 {
  font-weight: 900;
}

.fs_9 {
  font-size: 0.9rem;
}

.text-red {
  color: #d00505;
  font-weight: 300;
}

.bg_modal {
  background: #0000007d;
  /*background: radial-gradient(#040404ab, transparent);*/
}

h4 {
  color: black;
}

.f2Style {
  color: #dec264 !important;
}

.styleContent {
  border-radius: 7px;
  background-color: #1f1e1c;
}

.homeTile {
  color: #dec264;
}

.homeSubTitle {
  font-size: 0.8rem !important;
  color: #999;
}

.iAgent {
  margin-right: 3px;
  margin-left: 15px;
}

.block.block-themed>.block-header>.block-title {
  color: #111;
}

/* loterry */
.theme-light .card-lotto .card-box .title {
  color: #e6e6de;
}

.card-lotto .card-box.status-close .title {
  background-color: #881b1b;
}

.card-lotto .card-box .title {
  background-color: rgba(0, 0, 0, 0.25);
  font-size: 90%;
  border: 2px solid #be9b24;
  border-radius: .25rem;
  padding: 7px 0;
  margin-bottom: 10px;
}

.card-lotto .card-box.status-close .info {
  color: #222;
}

.card-lotto .card-box .info {
  font-size: 80%;
}

.card-lotto .card-box .time {
  font-size: 70%;
}

.card-lotto .card-box.status-close .time {
  color: #222;
}

.card-lotto .card-box {
  background: #f9f9f9;
  background: -moz-linear-gradient(top, #f9f9f9 0%, #d8d8d7 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f9f9f9), color-stop(100%, #d8d8d7));
  background: -webkit-linear-gradient(top, #f9f9f9 0%, #d8d8d7 100%);
  background: -o-linear-gradient(top, #f9f9f9 0%, #d8d8d7 100%);
  background: -ms-linear-gradient(top, #f9f9f9 0%, #d8d8d7 100%);
  background: linear-gradient(to bottom, #f9f9f9 0%, #d8d8d7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#cccccc', GradientType=0);
  height: 100%;
  text-align: center;
  border: 2px solid #be9b24;
  border-radius: .4rem;
  padding: 4px 4px 15px;
  display: block;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.card-lotto .card-box.yeekee {
  background: #ff6700;
  background: -moz-linear-gradient(top, #ff6700 0%, #c49d33 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff6700), color-stop(100%, #c49d33));
  background: -webkit-linear-gradient(top, #ff6700 0%, #c49d33 100%);
  background: -o-linear-gradient(top, #ff6700 0%, #c49d33 100%);
  background: -ms-linear-gradient(top, #ff6700 0%, #c49d33 100%);
  background: linear-gradient(to bottom, #ff6700 0%, #c49d33 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6700', endColorstr='#c49d33', GradientType=0);
}

.theme-light a:link,
.theme-light a:visited {
  color: #555;
}

.card-lotto .card-box.yeekee-vip {
  background: #5F2885;
  background: -moz-linear-gradient(top, #5F2885 0%, #b45aa8 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5F2885), color-stop(100%, #b45aa8));
  background: -webkit-linear-gradient(top, #5F2885 0%, #b45aa8 100%);
  background: -o-linear-gradient(top, #5F2885 0%, #b45aa8 100%);
  background: -ms-linear-gradient(top, #5F2885 0%, #b45aa8 100%);
  background: linear-gradient(to bottom, #5F2885 0%, #b45aa8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5F2885', endColorstr='#b45aa8', GradientType=0);
}

.card-lotto .card-box.status-open {
  background: #659773;
}

.theme-light .card-lotto .card-box .title {
  color: #e6e6de;
}

.title span {
  color: #e6e6de;
}

.yeekee-vip div {
  color: white;
}

.yeekee div {
  color: white;
}

.status-open div,
.status-open h2 {
  color: white;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #bdbdbd !important;
  border-color: #5f5e5d !important;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #0059d161 !important;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  box-shadow: 0 0 0 0.2rem #0059d161 !important;
}

a:link,
a:visited {
  color: #0a0a0a;
  text-decoration: none;
  outline: 0;
}

.font09 {
  font-size: 1rem;
  font-weight: 900;
}

.font08 {
  font-size: 0.8rem;
  font-weight: 700;
}

@media screen and (min-width: 767px) {
  .btn-group-sp-4 li {
      width: 10% !important;
  }
}

/* top btn */
@media screen and (max-width: 767px) {

  .w-md-100 {
      width: 100% !important;
  }

  .tab-nav-style-2.nav-tabs .nav-link {
      text-align: center;
      border-color: rgba(0, 0, 0, 0.125);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }

  .tab-nav-style-2.nav-tabs .nav-link.active,
  .tab-nav-style-2.nav-tabs .nav-item.show .nav-link {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }

  .tab-nav-style-2.nav-tabs .nav-link {
      text-align: center;
      border-color: rgba(0, 0, 0, 0.125);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }

  .tab-nav-style-2.nav-tabs .nav-popup .nav-link {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }

  .tab-nav-style-2.nav-tabs .nav-item {
      width: 50%;
  }
}

.nav-link {
  font-size: 0.9rem;
  font-weight: 100;
}

.tab-nav-style-4.nav-tabs .nav-item {
  width: 10%;
}

.btn-outline-warning {
  font-weight: 400;
  color: #343a40;
  border-color: #c3c3c3;
}

.btn-group-sp-4 li {
  padding: 1px;
  width: 20%;
  /*margin-right: 1%;*/
  margin-bottom: 4px;
  list-style-type: none;
}

.btn-group-sp-4 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.btn-permutation {
  border-radius: 3px;
  color: #252525;
  border-color: rgba(0, 0, 0, 0.125);
  border-bottom: none;
}

.btn-permutation:active {
  border-radius: 3px;
  font-weight: 900;
  background-color: #ffc107a6;
  color: #252525;
}

.tab-nav-style-4.nav-tabs .nav-link.active,
.tab-nav-style-4.nav-tabs .nav-item.show .nav-link {
  font-weight: 900;
  background-color: black;
  color: white;
  border-color: rgba(0, 0, 0, 0.125);
  border-bottom: none;
}

.tab-nav-style-4.nav-tabs .nav-link {
  border-radius: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #a0a6ab;
  /* background-color: #fff; */
}

.nav-tabs {
  border-bottom: 0px solid #ebebeb;
}

@media screen and (max-width: 991px) {

  .tab-nav-style-4.nav-tabs .nav-link.active,
  .tab-nav-style-4.nav-tabs .nav-item.show .nav-link {
      border-color: #f9f9f9;
      border-bottom: 1px solid #ffc107;
  }

  .tab-nav-style-4.nav-tabs .nav-link.active,
  .tab-nav-style-4.nav-tabs .nav-item.show .nav-link {
      /*background-color: #1d1c18;*/
      /*color: #e6e6de;*/
      border-color: rgba(0, 0, 0, 0.125);
      border-bottom: none;
  }

  .tab-nav-style-4.nav-tabs .nav-link {
      border-radius: 0;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-color: #8e8e8e;
      /* background-color: #fff; */
  }

  .tab-nav-style-4.nav-tabs .nav-item {
      width: 20%;
  }

  .tab-nav-style-2.nav-tabs .nav-link {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
  }
}

.tab-nav-style-2.nav-tabs .nav-link {
  font-weight: 300;
}

.tab-nav-style-2.nav-tabs .nav-link.btn-three {
  background-color: #5c80d1b3;
  color: #0a0a0a;
}

.theme-dark .nav-tabs .nav-link.btn-three {
  background: #e5e8ff;
  color: #000;
}

.tab-nav-style-2.nav-tabs .nav-link.active,
.tab-nav-style-2.nav-tabs .nav-item.show .nav-link {
  background: linear-gradient(143deg, rgba(3, 61, 26, 1) 10%, rgba(1, 70, 29, 1) 35%, rgba(24, 161, 80, 1) 100%) !important;
  color: #fff;
  border-color: rgba(0, 0, 0, 0.125);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.nav-link.active i {
  color: white !important;
}

.nav-tabs .nav-link.btn-two {
  border-color: white;
  border-color: white;
  background: #e5ffeb;
  color: #000;
}

.nav-tabs .nav-link.btn-two:active {
  border-color: white;
  background: #81e2c8;
  color: #000;
}

.nav-tabs .nav-link.btn-two-active {
  border-color: white;
  background: #81e2c8;
  color: #000;
}

.nav-tabs .nav-link.btn-run {
  border-color: white;
  border-color: white;
  background: #fffde5;
  color: #000;
}

.nav-tabs .nav-link.btn-run:active {
  border-color: white;
  background: #ffc107a6;
  color: #000;
}

.nav-tabs .nav-link.btn-run-active {
  border-color: white;
  background: #ffc107a6;
  color: #000;
}

.tab-nav-style-2.nav-tabs .nav-popup .nav-link {
  color: #000000;
  cursor: pointer;
  border-color: #fdd551;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.nav-tabs .nav-link.btn-three {
  border-color: white;
  background: #5c80d18c;
  color: #000;
}

.nav-tabs .nav-link.btn-three:active {
  border-color: white;
  background: #5c80d1;
  color: #000;
}

.nav-tabs .nav-link.btn-three-active {
  border-color: white;
  background: #5c80d1;
  color: #000;
}

.card {
  border-radius: 5px;
  /*background-color: #292c31;*/
  padding-top: 5px;
  padding-bottom: 5px;
  /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;*/
}

.box_shadow {
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.16);
}

.box_shadow:active {
  box-shadow: inset 1px 2px 2px 2px rgba(0, 0, 0, 0.16);
}

.box_shadow2 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.16);
}

.box_shadow2:active {
  box-shadow: inset 1px 2px 2px 2px rgba(0, 0, 0, 0.16);
}

.btn-outline-warning:active {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.16);
}

button.btn.btn-outline-warning.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
  background: #ffe9e9;
}

.nav-pills .nav-link.active,
.nav-pills.nav-change-select-number .show>.nav-link {
  color: black;
  background: #ffc107a6;
  border-radius: 0px;
}

.bet-keyboard .box-show-number .input-number {
  height: 60px;
  width: 100%;
  text-align: center;
}

.bet-keyboard .box-show-number .input-number .lists {
  height: 60px;
  width: auto;
  display: inline-block;
  position: relative;
}

.bet-keyboard .box-show-number .input-number .lists>label {
  float: left;
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 5px;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  border: 1px solid #9f8c5f;
  position: relative;
  border-radius: 5px;
  color: #005623;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545;
}

.btn-warning {
  color: #212529 !important;
  background-color: #ffc107 !important;
  border-color: #ffc107;
}

.btn-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d;
}

.form-control {
  font-weight: bold;
  background-color: #e8e8e8;
  color: #4c4c4c;
  border-color: rgba(0, 0, 0, 0.25);
}

.form-control:focus {
  background-color: #eaeaea;
  color: #e6e6de;
  border-color: rgba(0, 0, 0, 0.25);
}

.input-group-text {
  background-color: transparent;
  color: #191919;
  border-color: rgba(0, 0, 0, 0.25);
}

.input-group input {
  font-size: 0.9rem;
}

.txtSmall {
  font-size: 0.9rem;
}

.input-group-text {
  font-size: 0.9rem;
}

p {
  margin: 0px !important;
}

.btn {
  padding: 2px;
}

.nav-link {
  text-align: center;
  padding: 9px;
}

/* cut theme  */
.nav-pills .nav-link {
  background: #495a69;
  /*background: linear-gradient(to right, #1d4350 0%, #A43931 100%);*/
  color: #fff;
}

.nav-pills .nav-link:focus,
.nav-pills .nav-link:hover {
  background-color: #ffc107a6;
  color: black;
}

.nav-pills .nav-link:active,
.nav-pills .nav-link:active {
  box-shadow: inset 1px 2px 2px 2px rgba(0, 0, 0, 0.16);
}

.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}

.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-confirm .modal-body {
  color: #999;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}

.modal-confirm .btn-secondary {
  background: #c1c1c1;
}

.modal-confirm .btn-danger {
  background: #f15e5e;
}

.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}

.form-control:focus {
  background-color: #eaeaea;
  color: #191919;
}

</style>
